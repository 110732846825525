.blog-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px 0 50px;
    min-height: calc( 100vh - 170px);
    position: relative;
}

.blog__title {
    text-align: center;
    padding: 20px 20px 0px;
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--grey-active);
    font-family: var(--poppins);
}

.blog-image--building {
    min-width: 290px;
    height: auto;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}

.blog-image--building img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}