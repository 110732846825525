.form {
    max-width: 583px;
    min-width: 265px;
    width: 100%;
    padding: 0 10px;
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-image: var(--black-box);
    padding: 20px 0;
    text-align: center;
    box-shadow: var(--shadow-card);
}

.form-control {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    color: var(--grey-negative);
    font-size: 1.3rem;
    padding: 5px 0;
}

.form-control label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: var(--background);
}

.form-control .label--message {
    height: auto;
}

.form-control .icon {
    width: 16px;
    height: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.icon-name {
    background-image: url('../../assets/icons/person-24px.svg');
}

.icon-email {
    background-image: url('../../assets/icons/alternate_email-24px.svg');
}

.icon-message {
    background-image: url('../../assets/icons/email-24px.svg');
}

.icon-container {
    width: 30px;
    height: auto;
    background-color: var(--background);
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-control .input {
    border: 2px solid var(--background);
    display: block;
    width: 80%;
    max-width: 453px;
    font-family: "Roboto Mono", monospace;
}

.form-control .input:focus {
    outline: 0;
    border-color: var(--yellow-cv);
}

.form-control.success .input {
    border-color: #2ecc71;
}

.form-control.error .input {
    border-color: var(--error-color);
}

.form small {
    display: block;
    justify-self: flex-start;
    margin-top: 2px;
    color: var(--error-color);
    font-size: 1.3rem;
}

.form button {
    width: 140px;
    height: 30px;
    align-self: center;
    margin-top: 10px;
    background-color: var(--yellow-cv);
    color: var(--grey-negative);
    font-family: "Roboto Mono", monospace;
    font-size: 1rem;
    letter-spacing: 2px;
}

.message-confirm {
    color: var(--just-white);
    font-size: 1.6rem;
    text-align: center;

}