.home-main-container {
    width: 100%;
    min-height: calc(100vh - 140px);
    padding: 50px 0;
}

.featured-projects {
    position: relative;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.featured-projects h2 {
    position: relative;
    text-align: center;
    padding: 40px 0 30px
}

.go-to-portfolio, .featured-projects a {
    color: var(--grey-negative);
    cursor: pointer;
    font-size: 1.6rem;
    padding: 20px 0;
    transition: all 0.25s ease;
    text-decoration: underline;
    font-family: var(--poppins);
}

.go-to-portfolio:hover, .featured-projects a:hover {
    color: var(--just-white);
    text-decoration: none;
    transform: scale(1.1);
}