.login-register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    min-width: 320px;
    background-image: url('../../../assets/images/Miyayima.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 20px;
    position: relative;
  }
  
  .login-register:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, rgba(30, 30, 40, .93) 0%, rgba(30, 30, 40, .96) 70%, rgba(30, 30, 40, .99) 80%, #1e1e28 100%);
  }

  .login-form-container{
      position: relative;
      z-index: 999;
      width: 100%;
      margin: 0 auto;
      max-width: 420px;
  }

  .login-form-container h2{
    margin: 20px 0;
}

  .form-card--login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-card);
    background-image: var(--black-box);
    height: 380px;
    padding: 20px;
}

.form-card--login input{
    width: 100%;
    height: 40px;
    padding: 0 20px;
    margin-bottom: 40px;
    color: var(--grey-negative);
    font-size: 1.6rem;
    outline: none;

}

.form-card--login input:focus{

    border: 1px solid var(--yellow-cv);

}