header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 320px;
    height: 70px;
    background-color: #20202a;
}

.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.icon--option {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-image: url('../../assets/icons/more_vert-18dp.svg');
}

.icon--menu {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-image: url('../../assets/icons/menu-18.svg');
}