.item-list-container {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 290px);
    gap: 30px;
    padding: 20px;
}

.portfolio__building-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio__building-container h2{
    text-align: center;
    padding: 30px 30px 0;
}

.portfolio-image--building {
    min-width: 290px;
    height: auto;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}

.portfolio-image--building img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.loading-container p {
    font-size: 2rem;
    padding: 40px 0;
}