.portfolio-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px 0 50px;
    min-height: calc( 100vh - 170px);
    position: relative;
}

.portfolio__navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 15px;
}

.portfolio__title {
    color: var(--just-white);
    margin: 0 auto 15px;
}

.portfolio__navbar ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}

.portfolio__navbar ul li {
    padding-right: 20px;
    list-style: none;
    font-size: 1.2rem;
    font-family: 'Poppins', san-serif;
    font-weight: 600;
    letter-spacing: 1.5px;
    cursor: pointer;
    transition: color 0.25s ease;
    text-transform: uppercase;
    margin-bottom: 15px;
}

a {
    text-decoration: none;
    color: var(--grey-negative);
}

.portfolio__navbar ul li:hover {
    color: var(--just-white);
}

.no-selected {
    color: var(--grey-negative);
}

.selected {
    color: var(--just-white);
}