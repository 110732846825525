.hero {
    position: relative;
    width: 100%;
    min-width: 320px;
    /* height: 542px; */
    height: calc( 100vh - 170px);
    margin-bottom: 50px;
    padding: 0 15px;
    box-shadow: var(--shadow-card);
    text-align: center;
}

.hero-container {
    width: 100%;
    min-width: 290px;
    height: 100%;
    background-image: url('../../assets/images/Miyayima.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hero-container .hero-container__mask {
    width: 100%;
    height: 100%;
    padding: 60px 30px;
    background-image: linear-gradient(90deg, rgba(45, 45, 58, .9) 15%, rgba(45, 45, 58, .7) 50%, rgba(43, 43, 53, .7) 100%);
}

.hero .hero__title {
    align-self: center;
    margin-bottom: 16px;
    font-size: 4.6rem;
    line-height: 5rem;
    padding-bottom: 25px;
    color: var(--just-white);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.content__intro {
    justify-content: flex-start;
    align-self: center;
    font-size: 1.6rem;
    color: var(--yellow-cv);
    padding-top: 20px;
}

.hero .hero__text {
    display: flex;
    font-size: 1.6rem;
    color: var(--just-white);
    margin-bottom: 20px;
}

.hero .Typewriter::before {
    color: var(--yellow-cv);
    content: "<code>";
}

.hero .Typewriter::after {
    color: var(--yellow-cv);
    content: "</code>";
}

.hero .hero__button {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    width: 170px;
    height: 45px;
    margin-top: 2.6rem;
    cursor: pointer;
    background-color: var(--yellow-cv);
}

.hero .hero__button:hover {
    transform: translateY(-2px);
    color: #1e1e28;
}

.hero .hero__button a {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    text-decoration: none;
    color: #000000;
}

@media only screen and (min-width: 800px) {
    .hero .hero__title {
        align-self: center;
        margin-bottom: 16px;
        font-size: 5.6rem;
        line-height: 5rem;
        font-weight: 700;
        letter-spacing: 2px;
        /* padding-left: 150px; */
    }
    .content__intro {
        justify-content: flex-start;
        align-self: flex-start;
        font-size: 2.2rem;
        color: var(--yellow-cv);
        padding-top: 20px;
        padding: 0 150px;
    }
    .hero .hero__text {
        display: flex;
        text-align: center;
        font-size: 2rem;
        color: var(--just-white);
        margin-bottom: 20px;
    }
    .content {
        max-width: 800px;
    }
}