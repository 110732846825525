.slide-menu {
    position: fixed;
    right: -230px;
    bottom: 0;
    box-shadow: var(--shadow-card);
    transition: all 0.25s ease-in;
    width: 230px;
    height: 100vh;
    background-color: blanchedalmond;
    z-index: 99;
}

.slide-menu--activo {
    right: 0;
    top: 0;
}

.slide-menu__bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 70px;
    background-image: var(--black-box);
    padding: 15px;
}

.close-icon {
    width: 25px;
    height: 25px;
    background-image: url('../../assets/icons/close-24px.svg');
    cursor: pointer;
}

.icon-config:hover {
    background-color: #ffffff;
    border-radius: 50%;
}

.main-menu-slide {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px;
    line-height: 4rem;
    background-color: #20202a;
}

.main-menu-slide ul {
    list-style: none;
    padding-bottom: 100px;
}

.main-menu-slide a {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.main-menu-slide a:hover {
    text-decoration: underline;
}

.no-selected {
    color: var(--grey-negative);
}

.selected {
    color: var(--just-white);
}