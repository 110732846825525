.history-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px 0 50px;
    min-height: calc( 100vh - 170px);
    position: relative;
}

.history-container__components {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: initial;
}

.history-column h2 {
    padding: 30px 30px 0;
}