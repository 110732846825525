footer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;
    height: 70px;
    padding: 30px 10px;
    font-size: 1.2rem;
    color: var(--grey-active);
    background-color: #20202a;
    box-shadow: var(--shadow-card);
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 15px;
    background-image: var(--black-box);
}

.footer-container :nth-child(1) {
    width: 35%;
}

.footer-container :nth-child(2) {
    width: 65%;
    text-align: end;
    color: var(--grey-active);
    transform: all 0.3s ease;
}

.footer-container :nth-child(2):hover {
    width: 65%;
    text-align: end;
    color: var(--just-white);

}