.project-container {
    width: 284px;
    height: 190px;
    position: relative;
    overflow: hidden;
    box-shadow: var(--shadow-card);
}

.project-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 205%;
    background-color: #2d3436;
    opacity: 0.8;
    transition: all 0.25s;
    transform: translate(550px, 300px) rotate(45deg);
}

.project-container:hover::before {
    transform: translate(-40px, -85px) rotate(45deg);
}

.project__caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image-container {
    width: 284px;
    height: 190px;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-container .project__name {
    text-align: right;
    font-size: 17px;
    padding: 0px 20px 10px;
    margin-top: 20px;
    transform: translate(150px, -150px);
    transition: all 0.25s;
}

.project__name p {
    font-size: 1.2rem;
    color: var(--yellow-cv);
    padding-bottom: 5px
}

.project-container:hover .project__name {
    transform: translate(0, 0);
    transition-delay: 0.3s;
}

.project__detail {
    width: 100%;
    text-align: right;
    padding: 0px 20px;
    transition: all 0.25s;
    transform: translate(-200px, 200px);
}

.project__detail p {
    font-size: 1.2rem;
    font-family: var(--poppins);
    padding-bottom: 5px;
}

.project__detail small {
    text-transform: uppercase;
}

.project-container:hover .project__detail {
    transform: translate(0, 0);
    transition-delay: 0.4s;
}

.project__options {
    text-align: right;
    padding: 20px;
    transition: all 0.25s;
    transform: translate(0, 100px);
}

.project-container:hover .project__options {
    transform: translate(0, 0);
    transition-delay: 0.5s;
}

.project__options a {
    text-decoration: none;
    color: var(--grey-active);
}

.project__options svg {
    margin-left: 15px;
    cursor: pointer;
    transition: all 0.25s ease;
}

.project__options svg:hover {
    color: var(--just-white);
    transform: scale(1.1);
}

.project-image--building {
    min-width: 290px;
    height: auto;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}

.project-image--building img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}