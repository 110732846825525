.error-container {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px 0 50px;
    position: relative;
}

.error-container {
    color: var(--just-white);
}

.error-container span {
    font-size: 1.6rem;
    padding: 20px 0;
}

.cat-container {
    min-width: 290px;
    height: auto;
}

.cat-container img {
    width: 100%;
    height: 100%;
}

.go-to-home, .error-container a {
    color: var(--grey-negative);
    font-family: var(--poppins);
    cursor: pointer;
    font-size: 1.6rem;
    padding: 20px 0;
    transition: all 0.25s ease;
}

.go-to-home:hover, .error-container a:hover {
    color: var(--just-white);
    text-decoration: none;
    transform: scale(1.1);
}