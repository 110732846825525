:root {
  /*colors*/
  --just-white: #ffffff;
  --yellow-cv: #ffc107;
  --grey-negative: #8c8c8e;
  --grey-active: #cacace;
  --background: #20202a;
  --error-color: #e74c3c;
  --black-box: linear-gradient(159deg, #2d2d3a 0%, #2b2b35 100%);
  /*effects*/
  --shadow-card: 0 3px 8px 0 rgb(15 15 20 / 20%);
  /*fonts*/
  --poppins: 'Poppins', san-serif;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  font-family: 'Roboto Mono', monospace;
  width: 100%;
  min-width: 320px;
  scroll-behavior: smooth;
}

body {
  color: var(--grey-active);
}

.background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.pointer {
  cursor: pointer;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 140px);
  height: 100%;
  min-width: 320px;
  background-image: url('./assets/images/Miyayima.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

.main-container:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(180deg, rgba(30, 30, 40, .93) 0%, rgba(30, 30, 40, .96) 70%, rgba(30, 30, 40, .99) 80%, #1e1e28 100%);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 1.1;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.6rem;
}

.icon {
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  8% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}